import './Footer.css';

const Footer = ({onClick}) => {
    return (
        <div className="Footer">
            <h2>
                <button onClick={onClick}>
                    BildCodePrint_
                </button>
            </h2>
        </div>
    )
}

export default Footer