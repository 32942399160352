import './Header.css'

const Header = ({onClick}) => {

    return (
        <header className='Header'>

            <h1>
                <button onClick={onClick}>
                    Phase Was,
                </button>
            </h1>
        
        </header>
    )
}

export default Header