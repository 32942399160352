import './Contact.css';

const Contact = ({onClick}) => {

    return (
        <div className='Contact'>
            <div onClick={onClick} className='Contact-flexGrow'>
            </div>
            <div className='Contact-flex'>
                <div onClick={onClick} className='Contact-flexGrow'>
                </div>
                <p>
                    Cyrill Appert<br />
                    Eveline Schumacher
                </p>
                <p>
                    Phase Was.<br />
                    Am Wasser 55 <br />
                    8049 Zürich
                </p>
                <p>
                    +41 79 233 69 86
                </p>
                <p>
                    kollektiv@phasewas.ch
                </p>
                <div onClick={onClick} className='Contact-flexGrow'>
                </div>
                

            </div>
            <div onClick={onClick} className='Contact-flexGrow'>
            </div>
        </div>
    )
}

export default Contact
