import './OneClickGallery.css'

const OneClickGallery = ({image, onClick}) => {

    return (
        <div className="OneClickGallery">
            <div className="OneClickGallery-navigation">
                <div onClick={onClick.backward} className="OneClickGallery-navigation-back"></div>
                <div onClick={onClick.forward} className="OneClickGallery-navigation-forward"></div>
            </div>
            <img className="OneClickGallery-image" alt="by Phase Was." src={image} />
        </div>
    )

}

export default OneClickGallery