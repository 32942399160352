import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import OneClickGallery from "./components/OneClickGallery.js";
import Contact from "./components/Contact";
import About from "./components/About";

const App = () => {
  const [showContact, setShowContact] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const ContactClick = () => {
    setShowContact(!showContact);
    setShowAbout(false);
  };
  const AboutClick = () => {
    setShowAbout(!showAbout);
    setShowContact(false);
  };

  const galleryClick = {
    forward() {
      setCurrentIndex((currentIndex + 1).mod(images.length));
    },
    backward() {
      setCurrentIndex((currentIndex - 1).mod(images.length));
    },
  };
  const images = [
    "assets/img/001.jpg",
    "assets/img/002.jpg",
    "assets/img/003.jpg",
    "assets/img/004.jpg",
    "assets/img/005.jpg",
    "assets/img/006.jpg",
    "assets/img/007.jpg",
    "assets/img/008.jpg",
    "assets/img/009.jpg",
    "assets/img/010.jpg",
    "assets/img/011.jpg",
    "assets/img/012.jpg",
    "assets/img/013.jpg",
    "assets/img/014.jpg",
    "assets/img/015.jpg",
    "assets/img/016.jpg",
    "assets/img/017.jpg",
    "assets/img/018.jpg",
    "assets/img/019.jpg",
    "assets/img/020.jpg",
    "assets/img/021.jpg",
    "assets/img/022.jpg",
    "assets/img/023.jpg",
    "assets/img/024.jpg",
    "assets/img/025.jpg",
    "assets/img/026.jpg",
    "assets/img/027.jpg",
    "assets/img/028.jpg",
    "assets/img/029.jpg",
  ];

  return (
    <div className="App">
      <Header onClick={ContactClick} />
      {!showContact && !showAbout && (
        <OneClickGallery image={images[currentIndex]} onClick={galleryClick} />
      )}
      {showContact && <Contact onClick={ContactClick} />}
      {showAbout && <About onClick={AboutClick} />}
      <Footer onClick={AboutClick} />
    </div>
  );
};

export default App;
