import "./About.css";

const About = ({ onClick }) => {
  return (
    <div className="About">
      <div onClick={onClick} className="About-flexGrow"></div>
      <div className="About-flex">
        <div onClick={onClick} className="About-flexGrow"></div>
        <div className="About-section">
          <h3>Bild_</h3>
          <p className="About-p">
            Reportagen-, Portrait-, Event- und Werbefotografie
          </p>
          <p className="About-p">Video- und Audioproduktion</p>
          <p className="About-p">3D-Visualisierungen und Animation</p>
        </div>
        <div className="About-section">
          <h3>Code</h3>
          <p className="About-p">Web- und Appentwicklung</p>
          <p className="About-p">Social Media</p>
          <p className="About-p">Digitale Installationen</p>
        </div>
        <div>
          <h3>Print</h3>
          <p className="About-p">Grafikdesign</p>
          <p className="About-p">Branding</p>
          <p className="About-p">Druckvorstufe</p>
        </div>

        <div onClick={onClick} className="About-flexGrow"></div>
      </div>
      <div onClick={onClick} className="About-flexGrow"></div>
    </div>
  );
};

export default About;
